@import "./scss/bootstrap_util.css";
@import "./scss/drawer.css";
@import "./scss/stripe.css";

.App {
  text-align: center;
}

.fit {
  -webkit-overflow-scrolling: touch;
  position: absolute;
  top: 56px;
  bottom: 0;
  overflow: auto;
  width: 100%
}

body {
  background-color: white!important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.text-align-center {
  text-align: center;;
}

.invisible {
  visibility: hidden !important;
}

.line-through {
  text-decoration: line-through;
}

.App-link {
  color: #61dafb;
}

.radio-group svg {
  width: 20px;
}
  
.radio-group .MuiTypography-root {
  font-size: 14px;
}

.MuiContainer-root {
  padding: 0!important;
}