#drawer .close {
    cursor: pointer;

    z-index: 99;

    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
    position: absolute;
    right: 0;
    top: -11px;
}